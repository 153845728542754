import React from 'react';
import Post from '../components/post';
import LazyLoadImage from '../components/LazyLoadImage';


const DebtReliefPage = () => {
    const article = {
        id: '1d31ee84-d375-5139-90d3-5438f32fb088',
        title: 'Debt Relief: What It Means',
        slug: '/debt-relief/',
        date: '2018-01-24T18:46:12.000Z',
        modified: '2021-11-04T17:49:49.000Z',
        excerpt: 'Several companies offer &#8220;debt relief&#8221; options for consumers to get out from the crushing weight of the debt they have. Learn more about what these options are, and what might be the best for you.',
        featured_image: {
            source_url: '/media/what-debt-relief-means.jpg',
        },
        acf: {
            seo_title: '',
            robots: 'index, follow',
        },
        read_time_minutes: 7,
        content: (
            <>
                <p>
                    The
                    {' '}
                    <a target="_blank" href="https://www.newyorkfed.org/microeconomics/hhdc.html" rel="noreferrer">total household debt in America reached $12.96 trillion</a>
                    , a new record since 2008.
                </p>
                <p>For those whose debt has reached a point where it seems impossible to pay it all off, debt relief programs are an option to help avoid bankruptcy and make payments at an attainable level. </p>
                <p>There are a lot of debt relief companies promising to help people get out of debt via debt settlement, a method of negotiating with creditors to reduce the amount you have to repay.</p>
                <p>Unfortunately, those who are under the pressure of uncontrolled debt are also vulnerable to scams that present the illusion of resolving their problems, but just leave victims in a worse situation. </p>
                <p>
                    For instance, the government
                    {' '}
                    <a target="_blank" href="http://money.cnn.com/2017/10/13/pf/college/student-debt-relief-scams-ftc/index.html" rel="noreferrer">sued more than 30 companies</a>
                    {' '}
                    in October for a student debt relief scam.
                </p>
                <p><LazyLoadImage src="/media/workers-would-give-up-other-benefits-for-student-loan-repayment-benefit-2017-1.png" alt="Workers Would Give Up Other Benefits For Student Loan Repayment Benefit (2017)" /></p>
                <p>While debt relief companies can help you with negotiating settlements and arranging a payment plan, you need to be careful when deciding which debt relief service to trust with your financial future. </p>
                <p>Understanding how debt relief is supposed to work and what are the hallmarks of a scam are key to getting on the right path and avoiding the wrong one. </p>
                <p>Here are the things to look for and questions to ask yourself before signing on with one of these companies.</p>
                <h2 id="is-the-company-asking-you-to-pay-upfront-fees">Is the company asking you to pay upfront fees?</h2>
                <p>This is one of the most common debt relief scams.</p>
                <p>Disreputable debt relief operations will charge hundreds or thousands of dollars in fees to trusting customers who need help, only to leave the customers owing more money than ever. </p>
                <p>A reputable debt relief company will never ask you to pay fees until they have helped you negotiate a payment plan with your creditors. </p>
                <p>A good, legitimate debt relief company earns its money as a percentage of what you save on repaying your debts.</p>
                <p>
                    If you are dealing with a company that doesn&rsquo;t meet with you face-to-face, the Federal Trade Commission&rsquo;s
                    {' '}
                    <a target="_blank" href="http://www.ecfr.gov/cgi-bin/text-idx?c=ecfr&amp;SID=9f841627100bb5cd07c7032d48dfb372&amp;rgn=div8&amp;view=text&amp;node=16:1.0.1.3.34.0.32.3&amp;idno=16" rel="noreferrer">Telemarketing Sales Rule</a>
                    {' '}
                    prohibits the collection of any fees in advance of any settlement, reduction or alteration of debt.
                </p>

                <p>It has been a common issue among companies offering these services. </p>
                <p>
                    An
                    {' '}
                    <a target="_blank" href="http://commerce.senate.gov/public/?a=Files.Serve&amp;File_id=46b1a377-f3fb-470d-ab09-7249d3d017bf" rel="noreferrer">investigation by the U.S. Government Accountability Office</a>
                    {' '}
                    found that only one of 20 companies told GAO investigators in 2010 that fees would be collected after a successful settlement.
                </p>
                <p>
                    One way to find a trustworthy debt relief company is to work with a company that is certified by a professional industry organization, such as the
                    {' '}
                    <a target="_blank" href="http://www.americanfaircreditcouncil.org/" rel="noreferrer">American Fair Credit Council (AFCC)</a>
                    .
                </p>
                <p>Debt settlement companies that are AFCC members do not charge any upfront fees and agree to a strict Code of Conduct.</p>
                <h2 id="does-the-debt-relief-company-make-promises-that-sound-too-good-to-be-true">Does the debt relief company make promises that sound too good to be true?</h2>
                <p>If a debt relief company tells you they can settle your debt for &quot;pennies on the dollar,&quot; or that they can &quot;remove bad information from your credit report,&quot; this is a sign of a debt relief scam. </p>
                <p>
                    The truth, according to the
                    {' '}
                    <a target="_blank" href="http://www.ftc.gov/bcp/edu/microsites/moneymatters/dealing-with-debt-relief-services.shtml" rel="noreferrer">Federal Trade Commission</a>
                    , is that there is no guarantee that any creditor will accept less than full payment of a debt that you owe.
                </p>
                <p>And it is not possible for any debt relief service to &quot;fix&quot; your credit score by removing negative information from your credit report. </p>
                <p>If you have made late payments or failed to repay a debt, there are consequences for your credit score that cannot be removed.</p>
                <p>
                    Debt relief companies cannot guarantee you a certain percentage of
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=debt-relief&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    because every person&rsquo;s situation is different – so be wary of any debt relief company that promises you a huge percentage of debt relief.
                </p>
                <p>Ultimately, while debt settlement services can help negotiate on your behalf and help manage your payments to creditors, not all creditors will always agree to accept less money than they are owed.</p>
                <p>
                    Getting trumped-up guarantees without any proof, such as you&rsquo;ll only have to pay 50 percent of your debt, that the company has been 100 percent successful or that all
                    {' '}
                    <a href="https://www.creditloan.com/bad-credit-loans/">negative credit</a>
                    {' '}
                    issues will be wiped clean are signs of trouble.
                </p>
                <p><LazyLoadImage src="/media/biggest-reasons-cited-by-cardholders-for-racking-up-debt-2017-2.png" alt="Biggest Reasons Cited By Cardholders For Racking Up Debt (2017)" /></p>
                <p>
                    The
                    {' '}
                    <a target="_blank" href="http://denver.bbb.org/article/get-out-of-debt-without-getting-ripped-off-34663" rel="noreferrer">FTC and other consumer protection agencies have found</a>
&nbsp;that 10 percent or less of all of those participating in these programs successfully complete them.
                </p>
                <h2 id="has-anyone-made-consumer-complaints-against-this-debt-relief-company">Has anyone made consumer complaints against this debt relief company?</h2>
                <p>Before you sign up with a debt relief service, check with your state Attorney General to see if any consumer complaints have been registered about the debt relief company. </p>
                <p>Or you can start by doing a Google search for the name of the company, plus the word &quot;complaints.&quot; </p>
                <p>Find out if the debt relief company is involved in any government regulatory actions or lawsuits for deceptive or unfair practices.</p>
                <p>Do some homework. </p>
                <p>Search for the name of the company you are considering working with and use the word &quot;complaints&quot; to see if others have raised red flags. </p>
                <p>
                    Use the
                    {' '}
                    <a target="_blank" href="http://www.bbb.org/us/Find-Business-Reviews/" rel="noreferrer">BBB&rsquo;s complaint database</a>
                    {' '}
                    to see if the company has been a source of problems as well as how long it has been around (if there is a record on file).
                </p>
                <p>
                    The
                    {' '}
                    <a target="_blank" href="http://denver.bbb.org/article/get-out-of-debt-without-getting-ripped-off-34663" rel="noreferrer">Better Business Bureau</a>
                    {' '}
                    warns consumers to beware of companies using generic names that make it hard to find reviews.
                </p>
                <p>
                    Also, see if the company is part of any recognized association that reviews the credentials of members, such as the&nbsp;Association of Independent Consumer Credit Counseling Agencies&nbsp;and the
                    {' '}
                    <a target="_blank" href="http://www.nfcc.org/" rel="noreferrer">National Foundation for Credit Counseling</a>
                    .
                </p>
                <h2 id="does-the-debt-relief-company-tell-you-about-the-risks">Does the debt relief company tell you about the risks?</h2>
                <p>Debt relief can help people pay off their debts and save money compared to the total amount that they owe – but there are risks to debt relief and debt settlement plans as well. </p>
                <p>A trustworthy debt relief company will not just tell you the positive aspects of debt relief, they&rsquo;ll also advise you about the risks and possible downsides as well.&nbsp;</p>
                <p>
                    <a target="_blank" href="http://www.consumer.ftc.gov/articles/0150-coping-debt" rel="noreferrer">According to the FTC</a>
                    , debt relief companies are required to give customers a number of disclosures about the costs and possible risks of signing up for a debt settlement service, including:
                </p>
                <p><strong>– Price and terms. </strong></p>
                <p>The debt relief company must tell you exactly how much money it charges in fees and what are the conditions/limitations and expectations of its debt settlement services. </p>
                <p>You should know what you are going to pay and what level of service to expect.</p>
                <p><strong>– Timeframe for results.&nbsp;</strong></p>
                <p>
                    Debt relief takes time, because the debt settlement company has to negotiate with various creditors and make payments on your behalf using money that you&rsquo;ve saved over time in a dedicated
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=48&amp;sub1=debt-relief&amp;sub2=savings" rel="noopener noreferrer" target="_blank">savings</a>
                    {' '}
                    account.
                </p>
                <p>The debt relief company is required to tell you how long you can expect to wait before they are able to make a settlement offer to each of your creditors, based on your overall debt settlement plan. </p>
                <p>They must also tell you how much money or what percentage of debt you must save before they can make a debt settlement offer.</p>
                <p><strong>– Consequences for non-payment.&nbsp;</strong></p>
                <p>Many debt settlement companies ask you to stop making debt payments. </p>
                <p>Often, this is part of the strategy of your debt settlement program, because some creditors require an account to be delinquent before they will consider making a debt settlement. </p>
                <p>However, there can be consequences for your credit rating, so you might continue to receive collection calls, and you might even be vulnerable to a lawsuit for failing to pay your loans.</p>
                <p><LazyLoadImage src="/media/how-often-consumers-were-contacted-by-debt-collectors-3.png" alt="How Often Consumers Were Contacted By Debt Collectors?" /></p>
                <p>
                    Signing up for a debt settlement program with a debt relief company
                    {' '}
                    <a href="/debt-settlement/">can help you improve your financial situation in the long run</a>
                    , but there might be short-term or medium-term consequences and risks.
                </p>
                <p>A trustworthy debt relief company will talk with you openly and honestly about the risks as well as the benefits.</p>
                <h2 id="know-what-federal-rules-apply-to-debt-relief-companies">Know what federal rules apply to debt relief companies</h2>
                <p>
                    Debt relief companies
                    {' '}
                    <a target="_blank" href="http://www.consumer.ftc.gov/articles/0145-settling-credit-card-debt" rel="noreferrer">are required to provide</a>
                    {' '}
                    information in advance of a consumer signing up for the services, including the cost and the terms.
                </p>
                <p>Here are other details they must disclose:</p>
                <ul className="list">

                    <li>What fees can be assessed.</li>

                    <li>The anticipated length of time expected to get results, including how long it will be before a debt settlement offer will be made to each creditor.</li>

                    <li>How much money you have to accumulate in a debt settlement account, as well as the percentage of debt owed that must be deposited before a settlement offer is made.</li>

                    <li>What the consequences are if you stop making payments to creditors.</li>

                    <li>That the money you deposit is yours – along with any interest earned – and may be withdrawn without penalty.</li>
                </ul>
                <p>Whenever you work with a debt settlement company, don&rsquo;t be afraid to ask to read the fine print.</p>
                <p>Don&rsquo;t pay money until you understand what the money is for and until you are sure it is legal. </p>
                <p>
                    If you find that debt settlement doesn&rsquo;t work for you, there are other options like
                    {' '}
                    <a href="https://www.debtconsolidation.com/credit-counseling/how-it-works/">consumer credit counseling</a>
                    ,
                    {' '}
                    <a href="https://www.ac934intrk.com/3J67C/3K6X8K/?uid=43&amp;sub1=debt-relief&amp;sub2=debt-consolidiation" rel="noopener noreferrer" target="_blank">debt consolidation</a>
                    , or as a last resort, bankruptcy.
                </p>
                <p>Every possible solution has benefits and risks that you need to evaluate and weigh before you decide which path is right for you. </p>
            </>
        ),
    };

    return (
        <Post article={article} />
    );
};

export default DebtReliefPage;
